import React from "react";
function validate() {
  return true;
}
const Button = ({ label, classT }) => {
  return (
    <button disabled={validate()} type="submit" className={classT}>
      {label}
    </button>
  );
};

export default Button;
