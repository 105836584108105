import React, { useState, useEffect } from "react";
import Heading from "./common/heading";
import TopBrandCard from "./common/topBrandCard";
import { Carousel } from "react-responsive-carousel";
import { getSection2 } from "../services/section2";

// requires a loader
function Section2(props) {
  const [section2Data, setSection2] = useState([]);
  useEffect(() => {
    const result = getSection2();
    // console.log("dawb", result);
    setSection2(result);
  });
  // console.log("adawaaaa", Object.entries(section2Data));
  //Object.entries(section2Data).map((item) => console.log("aaaaa", item[1]));

  return (
    <div className="row">
      <Heading heading={"Top Brands"} />

      {Object.entries(section2Data).map((item, i) => (
        <div key={i} className="col-md-4">
          <Carousel
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
            stopOnHover={true}
          >
            {item[1].map((innerCard, j) => (
              <TopBrandCard key={innerCard.id} obj={innerCard} />
            ))}
          </Carousel>
        </div>
      ))}
    </div>
  );
}

export default Section2;
