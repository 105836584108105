const section = [
  {
    id: "5b21ca3eeb7f6fbccd471815",
    name: "Desert Oddities #1",
    img: "../assets/images/unnamed.jpg",
  },
  {
    id: "5b21ca3eeb7f6fbccd471816",
    name: "Desert Oddities #2",
    img: "../assets/images/popular-location-02.jpg",
  },
  {
    id: "5b21ca3eeb7f6fbccd471817",
    name: "Desert Oddities #3",
    img: "../assets/images/popular-location-03.jpg",
  },
  {
    id: "5b21ca3eeb7f6fbccd471819",
    name: "Desert Oddities #4",
    img: "../assets/images/popular-location-04.jpg",
  },
];

export function getSection() {
  return section;
}
