const section3 = [
  {
    id: "1",
    name: "Art",
    img: "../assets/images/category_art_icon.1e6772d83a053d87f6fa.svg",
    total: "32",
  },
  {
    id: "2",
    name: "Music",
    img: "../assets/images/category_music_icon.0427619b15ac52636894.svg",
    total: "12",
  },
  {
    id: "3",
    name: "Video",
    img: "../assets/images/category_video_icon.a3e286b257289d58dd43.svg",
    total: "11",
  },
  {
    id: "4",
    name: "Collectibies",
    img: "../assets/images/category_collectibles_icon.324f6828b93ac4dbf911.svg",
    total: "13",
  },
  {
    id: "5",
    name: "Sport",
    img: "../assets/images/category_sports_icon.b62dae7d38ff976b6951.svg",
    total: "22",
  },
  {
    id: "6",
    name: "Utility",
    img: "../assets/images/category_utility_icon.d6c3251b2b0d56826f42.svg",
    total: "56",
  },
];

export function getSection3() {
  return section3;
}
