import React from "react";
import Image from "../common/image";
function Card5(props) {
  return (
    <div className="row">
      <div className="col-lg-8 col-md-8">
        <div
          className="dashboard-list-box with-icons margin-top-20"
          style={{ overflow: "auto", height: "591px" }}
        >
          <h4>Transaction History (1267)</h4>
          <div style={{ padding: "10px" }}>
            <table className="table ">
              <thead>
                <tr>
                  <th>ITEM NAME</th>
                  <th>PRICE</th>
                  <th>FROM</th>
                  <th>TO</th>
                  <th>DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Image
                      path={"../assets/images/ITEM_PREVIEW2.jpg"}
                      classT={"sqimage"}
                    />

                    <strong>NFT Sold</strong>
                  </td>
                  <td>
                    <strong>Ξ 0.0004</strong>
                  </td>
                  <td>
                    <Image
                      path={
                        "../assets/images/PROFILE_cc161401-a05b-4650-aed1-98ea3a1c81c0.jpeg"
                      }
                      classT={"sqimage"}
                    />
                    Seller<strong> finance</strong>
                  </td>
                  <td>
                    <Image
                      path={
                        "../assets/images/PROFILE_a0f2f30a-c67e-4dcb-90bf-9f13a3f57a2c.jpeg"
                      }
                      classT={"sqimage"}
                    />
                    Buyer<strong> inance</strong>
                  </td>
                  <td>
                    <strong>4 hours ago </strong>
                    <br />
                    Apr 19, 07:14 pm
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-4">
        <div
          className="dashboard-list-box with-icons margin-top-20"
          style={{ overflow: "auto", height: "591px" }}
        >
          <h4>Top Seller</h4>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <Image
                    path={
                      "../assets/images/crown_icon.103e6723fa1a13eae95a.svg"
                    }
                    classT={"sqimage ml27 w30"}
                  />
                </td>
                <td>
                  <Image
                    path={
                      "../assets/images/PROFILE_cc161401-a05b-4650-aed1-98ea3a1c81c0.jpeg"
                    }
                    classT={"sqimage"}
                  />
                  0x5dc...986d<strong> Ξ 0980</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <Image
                    path={
                      "../assets/images/crown_icon.103e6723fa1a13eae95a.svg"
                    }
                    classT={"sqimage ml27 w30"}
                  />
                </td>
                <td>
                  <Image
                    path={
                      "../assets/images/PROFILE_cc161401-a05b-4650-aed1-98ea3a1c81c0.jpeg"
                    }
                    classT={"sqimage"}
                  />
                  0x5dc...9s86d<strong> Ξ 09a80</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <Image
                    path={
                      "../assets/images/crown_icon.103e6723fa1a13eae95a.svg"
                    }
                    classT={"sqimage ml27 w30"}
                  />
                </td>
                <td>
                  <Image
                    path={
                      "../assets/images/PROFILE_cc161401-a05b-4650-aed1-98ea3a1c81c0.jpeg"
                    }
                    classT={"sqimage"}
                  />
                  0x5dc...98a6d<strong> Ξ 09x80</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <center>4</center>
                </td>
                <td>
                  <Image
                    path={
                      "../assets/images/PROFILE_cc161401-a05b-4650-aed1-98ea3a1c81c0.jpeg"
                    }
                    classT={"sqimage"}
                  />
                  0x5dc...98a6d<strong> Ξ 0s980</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Card5;
