import React from "react";
import Image from "../common/image";
import { Accordion, AccordionItem } from "react-light-accordion";
function SideFilter(props) {
  return (
    <div className="col-lg-3 col-md-4">
      <h1>Filter</h1>
      <Accordion atomic={true}>
        <AccordionItem title="Price">
          <div className="row with-forms" style={{ marginTop: "10px" }}>
            <div className="col-md-12">
              <select
                data-placeholder="All Categories"
                className="chosen-select"
              >
                <option>United States Dollar (USD)</option>
                <option>Ether (ETH)</option>
                <option>Solana (SOL)</option>
              </select>
            </div>
          </div>
          <div className="row with-forms">
            <div className="col-md-6">
              <input type="text" placeholder="Min" />
            </div>
            <div className="col-md-6">
              <input type="text" placeholder="Max" />
            </div>
          </div>
          <div className="row with-forms" style={{ marginBottom: "10px" }}>
            <button className="button ">Apply</button>
          </div>
        </AccordionItem>

        <AccordionItem title="Collection">
          <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-md-12 ">
              <div className="row with-forms">
                <div className="input-with-icon">
                  <i className="sl sl-icon-magnifier"></i>
                  <input type="text" placeholder="What are you looking for?" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 heightoverflow">
              <div className="messages-inbox">
                <ul className="slim-scroll">
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <img
                          src="https://lh3.googleusercontent.com/oyEO1RajjrJuCYtbXX9Oa3FhoRvX5BO_TkFc8PTUkN5egJwFG2mlM0lRl7zuOvBj_HciCY35D4exJm0b3DZya6qxpyhk0aBN7oCH=s64"
                          alt=""
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>
                            Kathy Brown <i>Unread</i>
                          </h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <img
                          src="https://lh3.googleusercontent.com/oyEO1RajjrJuCYtbXX9Oa3FhoRvX5BO_TkFc8PTUkN5egJwFG2mlM0lRl7zuOvBj_HciCY35D4exJm0b3DZya6qxpyhk0aBN7oCH=s64"
                          alt=""
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>
                            Kathy Brown <i>Unread</i>
                          </h5>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </AccordionItem>

        <AccordionItem title="Chains">
          <div className="row" style={{ marginBottom: "25px" }}>
            <div className="col-md-12">
              <div className="messages-inbox">
                <ul className="slim-scroll">
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image path={"../../assets/images/ethereum.png"} />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Ethereum</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image
                          path={"../../assets/images/polygon.svg"}
                          classT={"w12"}
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Polygon</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image path={"../../assets/images/klaytn.png"} />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Klaytn</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image
                          path={"../../assets/images/solana.svg"}
                          classT={"w12"}
                        />

                        {/* <img src="images/" alt="" style={{ width: "15%" }} /> */}
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Solana</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </AccordionItem>
        <AccordionItem title="Categories">
          <div className="row" style={{ marginBottom: "25px" }}>
            <div className="col-md-12 heightoverflow">
              <div className="messages-inbox">
                <ul className="slim-scroll">
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image
                          path={"../../assets/images/art-light.svg"}
                          classT={"w40"}
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Art</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image
                          path={"../../assets/images/collectibles-light.svg"}
                          classT={"w40"}
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Collectibles</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image
                          path={"../../assets/images/domain-names-light.svg"}
                          classT={"w40"}
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Domain Names</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image
                          path={"../../assets/images/music-light.svg"}
                          classT={"w40"}
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Music</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image
                          path={
                            "../../assets/images/photography-category-light.svg"
                          }
                          classT={"w40"}
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Photography</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image
                          path={"../../assets/images/sports-light.svg"}
                          classT={"w40"}
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Sports</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image
                          path={"../../assets/images/trading-cards-light.svg"}
                          classT={"w40"}
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Trading Cards</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image
                          path={"../../assets/images/utility-light.svg"}
                          classT={"w40"}
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Utility</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="unread" style={{ marginTop: "17px" }}>
                    <a href="#">
                      <div className="message-avatar">
                        <Image
                          path={"../../assets/images/virtual-worlds-light.svg"}
                          classT={"w40"}
                        />
                      </div>

                      <div className="message-by">
                        <div className="message-by-headline">
                          <h5>Virtual Worlds</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default SideFilter;
