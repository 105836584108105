import React from "react";
import Image from "../common/image";
function Card4(props) {
  return (
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <div className="dashboard-list-box with-icons margin-top-20">
          <h4>Metadata</h4>
          <div id="add-listing">
            <div className="add-listing-section">
              <div className="row with-forms">
                <div className="col-md-12 " style={{ marginTop: "15px" }}>
                  <div className="row">
                    <div className="col-md-4">
                      <h3>
                        <b>NFT Info</b>
                      </h3>
                      <ul>
                        <div className="row">
                          <div className="col-md-12">
                            <li style={{ paddingLeft: "62px" }}>
                              <Image
                                path={"../assets/images/listing-item-01.jpg"}
                                classT={"image3 l0"}
                              />

                              <b>
                                Contract Address <br />
                              </b>
                              <a href="#" style={{ color: "blue" }}>
                                0xc23d925684919c1619...
                              </a>
                            </li>
                          </div>
                          <p style={{ marginLeft: "10px" }}>
                            <b>Token ID</b>
                            <br />
                            <div className="input-group">
                              <input
                                style={{
                                  borderRadius: "30px",
                                  height: "36px",
                                }}
                                value="101965780493397326025557442139517565369360690998853702229753473331203131508246"
                                type="text"
                                className="form-control"
                                readOnly
                              />
                            </div>
                          </p>
                          <p style={{ marginLeft: "10px" }}>
                            <b>Token Name</b>
                            <br />
                            Redwood relaxation
                          </p>
                          <p style={{ marginLeft: "10px" }}>
                            <b>Original Image on NFT</b>
                            <br />
                            <a href="#"> View original image</a>
                          </p>
                          <p
                            style={{
                              marginLeft: "10px",
                              marginBottom: "-3px",
                            }}
                          >
                            <b>Original Creator</b>
                            <li style={{ paddingLeft: "62px" }}>
                              <Image
                                path={"../assets/images/listing-item-01.jpg"}
                                classT={"image3 l0"}
                              />

                              <b>
                                true_artist
                                <br />
                              </b>
                              <a href="#" style={{ color: "blue" }}>
                                0xc23d925684919c1619...
                              </a>
                            </li>
                          </p>
                        </div>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <h3>
                        <b>Royalties</b>
                      </h3>
                      <ul>
                        <div className="row">
                          <p
                            style={{
                              marginLeft: "16px",
                              marginTop: "17px",
                            }}
                          >
                            <b>Store Royalty</b>
                            <br />
                            0%
                          </p>
                          <p
                            style={{
                              marginLeft: "16px",
                              marginTop: "15px",
                            }}
                          >
                            <b>Onchain NFT royalty to creator</b>
                            <br />
                            16%
                          </p>
                          <p
                            style={{
                              marginLeft: "16px",
                              marginTop: "45px",
                            }}
                          >
                            <b>Mintable fee</b>
                            <br />
                            0% NO TRADING FEES!
                          </p>
                        </div>
                      </ul>
                    </div>
                    <div className="col-md-4">
                      <div className="metaData">
                        {`"owner": "0xe16E9629A853CA60928105326503Ea4EE817Dabf",
                                            "symbol": "Mintable Shared Gasless store",
                                            "image": "https://d1iczm3wxxz9zd.cloudfront.net/f0ed38b7-2914-450c-804a-a50443d823b6/000000-0000000002/101965780493397326025557442139517565369360690998853702229753473331203131508246/ITEM_PREVIEW1.jpg",
                                            "animation_url": "",
                                            "copyright_transfer": true,
                                            "address": "0xc23d925684919c1619f13427817d3fee6c24debb",
                                            "tokenId": "101965780493397326025557442139517565369360690998853702229753473331203131508246",
                                            "resellable": true,
                                            "sub_category": "photography",
                                            "original_creator": "0xe16E9629A853CA60928105326503Ea4EE817Dabf",
                                            "edition_number": 1,
                                            "description": "Sit under the redwoods and relax ",
                                            "auctionLength": 0,
                                            "title": "Redwood relaxation ",
                                            "file_key": "",
                                            "token_id": "101965780493397326025557442139517565369360690998853702229753473331203131508246",
                                            "apiURL": "mintable_gasless/",
                                            "name": "Redwood relaxation ",
                                            "auctionType": "Fixed",
                                            "category": "Art",
                                            "edition_total": 1,
                                            "gasless": true`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card4;
