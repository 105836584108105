import React from "react";
import Image from "./image";
function TopSellerCard(props) {
  return (
    <a href="#">
      <div className="card2">
        <Image
          classT={"image3"}
          style={{ marginTop: "8px" }}
          path={"../assets/images/listing-item-01.jpg"}
        />
        <div
          className="col-md-1"
          style={{ float: "left", marginLeft: "80px", marginTop: "8px" }}
        >
          <p>
            0x1f...
            <br />
            <b>140.2</b>
          </p>
        </div>
        <div
          className="col-md-1"
          style={{
            float: "right",
            marginRight: "65px",
            marginTop: "8px",
          }}
        >
          <p>
            View
            <br />
            <b>140</b>
          </p>
        </div>
        <div
          className="col-md-1"
          style={{
            float: "right",
            marginRight: "21px",
            marginTop: "8px",
          }}
        >
          <p>
            Item
            <br />
            <b>140</b>
          </p>
        </div>
      </div>
    </a>
  );
}

export default TopSellerCard;
