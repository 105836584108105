import React from "react";
import Image from "../common/image";
import Button from "../common/from";
function DetailCard(props) {
  return (
    <div className="col-lg-6 col-md-6">
      <div className="dashboard-list-box invoices with-icons margin-top-20">
        <ul>
          <li style={{ paddingLeft: "17px" }}>
            <strong>Mintable Gasless store</strong>
            <i
              className="fa fa-envelope"
              style={{ marginLeft: "22px", fontSize: "22px" }}
            >
              <i className="fa fa-share-alt" style={{ marginLeft: "5px" }}></i>
            </i>
            <i
              className="fa fa-ellipsis-h"
              style={{
                float: "right",
                marginTop: "1px",
                fontSize: "22px",
              }}
            ></i>
            <h3>
              <b>Venom Devil Ape</b>
            </h3>

            <ul>
              <li>Venom Devil Ape</li>
            </ul>
          </li>
          <div className="row">
            <div className="col-md-6" style={{ marginTop: "10px" }}>
              <b style={{ marginLeft: "20px" }}>Seller</b>
              <li>
                <Image
                  path={"../assets/images/listing-item-01.jpg"}
                  classT={"image3"}
                />

                <strong>coolapeclub1</strong>
              </li>
            </div>
            <div className="col-md-6" style={{ marginTop: "10px" }}>
              <b style={{ marginLeft: "20px" }}>Store</b>
              <li>
                <Image
                  path={"../assets/images/listing-item-01.jpg"}
                  classT={"image3"}
                />
                <strong>exeas as</strong>
              </li>
            </div>
            <div className="col-md-6" style={{ marginTop: "20px" }}>
              <b style={{ marginLeft: "20px" }}>Rating</b>
              <li style={{ paddingLeft: "17px", marginTop: "-20px" }}>
                <strong>
                  <i style={{ color: "yellow", fontSize: "24px" }}>&#9733;</i>
                  <b style={{ marginLeft: "7px" }}>5</b>
                </strong>
              </li>
            </div>
            <div className="col-md-6" style={{ marginTop: "20px" }}>
              <b style={{ marginLeft: "20px" }}>Votes</b>
              <li style={{ paddingLeft: "17px", marginTop: "-20px" }}>
                <strong>10 vote</strong>
              </li>
            </div>
          </div>
          <hr style={{ margin: "0px 0" }} />
          <li style={{ paddingLeft: "17px" }}>
            <strong>
              Price
              <br />
              0.124 ($50.26)
            </strong>
            <ul>
              <Button
                classT={"button add-pricing-list-item"}
                label={"Buy Now"}
              />
              <Button
                classT={"button add-pricing-submenu "}
                label={"Buy With Credit Card"}
              />
            </ul>
          </li>
          <li>
            <ul style={{ textAlign: "center" }}>
              <li className="fa fa-envelope">&nbsp;&nbsp;&nbsp;2</li>
              <li className="fa fa-eye">&nbsp;&nbsp;&nbsp;536</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DetailCard;
