import React from "react";
import Card from "./detailComponents/card";
import Card2 from "./detailComponents/card2";
import Card3 from "./detailComponents/card3";
import Card4 from "./detailComponents/card4";
import Card5 from "./detailComponents/card5";
function Header(props) {
  return (
    <React.Fragment>
      <Card />
      <Card2 />
      <Card3 />
      <Card4 />
      <Card5 />
    </React.Fragment>
  );
}

export default Header;
