import React, { useState, useEffect } from "react";
import Heading from "./common/heading";
import { getSection3 } from "../services/section3";
function Section3(props) {
  const [section3Data, setSection3] = useState([]);
  useEffect(() => {
    let result = getSection3();
    // console.log("dawb", result);
    // result = result.slice(0, 6);
    setSection3(result);
  });
  return (
    <div className="row">
      <Heading heading={"Popular Categories"} />
      <div className="col-md-12">
        <div className="categories-boxes-container margin-top-5 margin-bottom-30">
          {section3Data.map((item) => (
            <a href="#" className="category-small-box" key={item.id}>
              <img src={item.img} style={{ height: "40px" }} alt="" />
              <h4>{item.name}</h4>
              <span className="category-box-counter">{item.total}</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Section3;
