import React, { useState, useEffect } from "react";
import Image from "../common/image";
function Grid(props) {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <a href="#" className="listing-item-container">
            <div className="listing-item">
              <Image path={"../../assets/images/unnamed.gif"} />
              <div className="listing-item-details">
                <ul>
                  <li>
                    Price{" "}
                    <Image
                      classT={"w10"}
                      path={
                        "../../assets/images/6f8e2979d428180222796ff4a33ab929.svg"
                      }
                    />{" "}
                    <b> 2.75</b>
                  </li>
                </ul>
              </div>
              <div className="listing-badge now-open">Now Open</div>

              <div className="listing-item-content">
                <span className="tag">Moon Ape Pet</span>
                <h3>
                  Moon Ape Pet<i className="verified-icon"></i>
                </h3>
                <span>Free 3D Genesis</span>
              </div>
              <span className="like-icon"></span>
            </div>
            <div className="star-rating" data-rating="3.5">
              <div className="rating-counter">(12 reviews)</div>
            </div>
          </a>
        </div>

        <div className="col-lg-6 col-md-12">
          <a href="#" className="listing-item-container">
            <div className="listing-item">
              <Image path={"../../assets/images/unnamed (1).gif"} />
              <div className="listing-item-details">
                <ul>
                  <li>
                    Price{" "}
                    <Image
                      classT={"w10"}
                      path={
                        "../../assets/images/6f8e2979d428180222796ff4a33ab929.svg"
                      }
                    />{" "}
                    <b> 2.75</b>
                  </li>
                </ul>
              </div>
              <div className="listing-item-content">
                <span className="tag">Moon Ape Pet</span>
                <h3>Moon Ape Pet</h3>
                <span>Free 3D Genesis</span>
              </div>
              <span className="like-icon"></span>
            </div>
            <div className="star-rating" data-rating="5.0">
              <div className="rating-counter">(23 reviews)</div>
            </div>
          </a>
        </div>

        <div className="col-lg-6 col-md-12">
          <a href="#" className="listing-item-container">
            <div className="listing-item">
              <Image path={"../../assets/images/unnamed.png"} />
              <div className="listing-item-details">
                <ul>
                  <li>
                    Price{" "}
                    <Image
                      classT={"w10"}
                      path={
                        "../../assets/images/6f8e2979d428180222796ff4a33ab929.svg"
                      }
                    />{" "}
                    <b> 2.75</b>
                  </li>
                </ul>
              </div>
              <div className="listing-item-content">
                <span className="tag">Fatties</span>
                <h3>Fat Ape Cube</h3>
                <span>Fat Ape Cube</span>
              </div>
              <span className="like-icon"></span>
            </div>
            <div className="star-rating" data-rating="2.0">
              <div className="rating-counter">(17 reviews)</div>
            </div>
          </a>
        </div>

        <div className="col-lg-6 col-md-12">
          <a href="#" className="listing-item-container">
            <div className="listing-item">
              <Image path={"../../assets/images/unnamed (2).gif"} />
              <div className="listing-item-details">
                <ul>
                  <li>
                    Price{" "}
                    <Image
                      classT={"w10"}
                      path={
                        "../../assets/images/6f8e2979d428180222796ff4a33ab929.svg"
                      }
                    />{" "}
                    <b> 2.75</b>
                  </li>
                </ul>
              </div>
              <div className="listing-badge now-open">Now Open</div>

              <div className="listing-item-content">
                <span className="tag">Hacked Ape Yach</span>
                <h3>
                  Hacked Ape Yach Club<i className="verified-icon"></i>
                </h3>
                <span>Bundle of 3 SAVE GAS</span>
              </div>
              <span className="like-icon"></span>
            </div>
            <div className="star-rating" data-rating="5.0">
              <div className="rating-counter">(31 reviews)</div>
            </div>
          </a>
        </div>

        <div className="col-lg-6 col-md-12">
          <a href="#" className="listing-item-container">
            <div className="listing-item">
              <Image path={"../../assets/images/unnamed (1).png"} />
              <div className="listing-item-details">
                <ul>
                  <li>
                    Price{" "}
                    <Image
                      classT={"w10"}
                      path={
                        "../../assets/images/6f8e2979d428180222796ff4a33ab929.svg"
                      }
                    />{" "}
                    <b> 2.75</b>
                  </li>
                </ul>
              </div>
              <div className="listing-badge now-closed">Now Closed</div>

              <div className="listing-item-content">
                <span className="tag">Prime Apes Club</span>
                <h3>Prime Apes Club</h3>
                <span>Save gas. 4xApes</span>
              </div>
              <span className="like-icon"></span>
            </div>
            <div className="star-rating" data-rating="5.0">
              <div className="rating-counter">(31 reviews)</div>
            </div>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Grid;
