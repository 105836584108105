import React, { useState, useEffect } from "react";
import Button from "./common/from";
import { getSection } from "../services/section";
import Image from "./common/image";

function Section(props) {
  const [sectionData, setSection] = useState([]);
  useEffect(() => {
    const result = getSection();
    // console.log("dawb", result);
    setSection(result);
  });
  //   console.log("dawn new", sectionData);
  return (
    <div className="row">
      <section
        className="fullwidth taxonomy-gallery-container margin-top-70 padding-top-75 padding-bottom-70"
        data-background-color="#f9f9f9"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="taxonomy-gallery-text"
                style={{ fontSize: "31px" }}
              >
                <h2>Discover The Power of Gamified NFTs</h2>
                <p style={{ fontSize: "19px" }}>
                  Spot n Win Customer Borne Voucher To Get Discounts on Your
                  Favorite Brand
                </p>
                <Button label={"Discover"} classT={"button margin-top-25"} />
                <Button label={"Create"} classT={"button margin-top-25"} />
              </div>
            </div>
          </div>
        </div>

        <div className="gallery-wrap">
          {sectionData.map((item) => (
            <a href="#" className="item" key={item.id}>
              <h3>
                {item.name}
                <span>
                  <Button label={"Buy Now"} classT={"button margin-top-25"} />
                  <Button label={"Spot"} classT={"button margin-top-25"} />
                </span>
              </h3>
              <Image path={item.img} />
            </a>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Section;
