import React from "react";
import Image from "./image";
function NewNftCard(props) {
  const {
    id,
    name,
    price,
    text,
    background_img,
    profile_img,
    owner_name,
    views,
  } = props.obj;
  return (
    <div className="card h526px" style={{ height: "516px !important" }}>
      <Image path={background_img} classT={"image1"} />
      <div className="container">
        <div className="row">
          <div className="col-md-2">
            <p style={{ float: "left" }}>
              MINT <br />
              <b>{name}</b>
            </p>
          </div>
          <div className="col-md-2">
            <p style={{ float: "right", marginRight: "29px" }}>
              Price <br />
              <b>{price}</b>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <p
              style={{
                fontSize: "13px",
                lineHeight: "19px",
                marginRight: "10px",
              }}
            >
              <b>{text}</b>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <hr style={{ marginRight: "32px" }} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-1">
            <Image path={profile_img} classT={"image3 image4"} />
          </div>
          <div className="col-md-1">
            <p style={{ marginLeft: "-65px", marginTop: "-9px" }}>
              Owner
              <br />
              <b>{owner_name}</b>
            </p>
          </div>
          <div className="col-md-1">
            <p>
              <i className="fa fa-eye buttonrow">{views}</i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewNftCard;
