import React from "react";
import Image from "../common/image";
import DetailCard from "./detailCard";
function Card(props) {
  return (
    <div className="row">
      <div className="col-lg-6 col-md-6">
        <div className="dashboard-list-box with-icons margin-top-20">
          <Image
            path={"../assets/images/listing-item-01.jpg"}
            classT={"image1 w100 h36"}
          />
        </div>
      </div>
      <DetailCard />
    </div>
  );
}

export default Card;
