import React, { useState, useEffect } from "react";
import Image from "../common/image";
function List(props) {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="listing-item-container list-layout">
            <a href="#" className="listing-item">
              <div className="listing-item-image">
                <Image path={"../../assets/images/unnamed.gif"} />
                <span className="tag">Moon Ape Pet</span>
              </div>

              <div className="listing-item-content">
                <div className="listing-badge now-open">Now Open</div>
                <div className="listing-item-inner">
                  <h3>
                    Moon Ape Pet <i className="verified-icon"></i>
                  </h3>
                  <span>Free 3D Genesis</span>
                  <div className="star-rating" data-rating="3.5">
                    <div className="rating-counter">(12 reviews)</div>
                  </div>
                </div>
                <div className="listing-item-details">
                  Price
                  <Image
                    classT={"w10"}
                    path={
                      "../../assets/images/6f8e2979d428180222796ff4a33ab929.svg"
                    }
                  />
                  <b> 2.75</b>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <div className="listing-item-container list-layout">
            <a href="#" className="listing-item">
              <div className="listing-item-image">
                <Image path={"../../assets/images/unnamed (1).gif"} alt="" />
                <span className="tag">Moon Ape Pet</span>
              </div>

              <div className="listing-item-content">
                <div className="listing-badge now-open">Now Open</div>
                <div className="listing-item-inner">
                  <h3>
                    Moon Ape Pet <i className="verified-icon"></i>
                  </h3>
                  <span>Free 3D Genesis</span>
                  <div className="star-rating" data-rating="3.5">
                    <div className="rating-counter">(12 reviews)</div>
                  </div>
                </div>
                <div className="listing-item-details">
                  Price
                  <Image
                    classT={"w10"}
                    path={
                      "../../assets/images/6f8e2979d428180222796ff4a33ab929.svg"
                    }
                  />
                  <b> 2.75</b>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <div className="listing-item-container list-layout">
            <a href="#" className="listing-item">
              <div className="listing-item-image">
                <Image path={"../../assets/images/unnamed.png"} alt="" />
                <span className="tag">Fatties</span>
              </div>
              <div className="listing-item-content">
                <div className="listing-item-inner">
                  <h3>Fat Ape Cube</h3>
                  <span>Fat Ape Cube </span>
                  <div className="star-rating" data-rating="2.0">
                    <div className="rating-counter">(17 reviews)</div>
                  </div>
                </div>
                <div className="listing-item-details">
                  Price
                  <Image
                    classT={"w10"}
                    path={
                      "../../assets/images/6f8e2979d428180222796ff4a33ab929.svg"
                    }
                  />
                  <b> 2.75</b>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default List;
