const section2 = {
  card: [
    {
      id: "1",
      name: "Sephoraa",
      table_name: "Sephora",
      background_img: "../assets/images/listing-item-01.jpg",
      profile_img: "../assets/images/listing-item-01.jpg",
      table_data: {
        item: "1000",
        views: "2000",
        floor_price: "30000",
        volume: "40000",
      },
    },
    {
      id: "2",
      name: "Sephoraa",
      table_name: "Sephora",
      background_img: "../assets/images/unnamed.gif",
      profile_img: "../assets/images/listing-item-01.jpg",
      table_data: {
        item: "1000",
        views: "2000",
        floor_price: "30000",
        volume: "40000",
      },
    },
    {
      id: "3",
      name: "Sephoraa",
      table_name: "Sephora",
      background_img: "../assets/images/listing-item-01.jpg",
      profile_img: "../assets/images/listing-item-01.jpg",
      table_data: {
        item: "1000",
        views: "2000",
        floor_price: "30000",
        volume: "40000",
      },
    },
  ],
  card2: [
    {
      id: "4",
      name: "Sephoaaaaaraa",
      table_name: "Sephora",
      background_img: "",
      profile_img: "",
      table_data: {
        item: "1000",
        views: "2000",
        floor_price: "30000",
        volume: "40000",
      },
    },
    {
      id: "5",
      name: "Sephoraa",
      table_name: "Sephora",
      background_img: "",
      profile_img: "",
      table_data: {
        item: "1000",
        views: "2000",
        floor_price: "30000",
        volume: "40000",
      },
    },
    {
      id: "6",
      name: "Sephoraa",
      table_name: "Sephora",
      background_img: "",
      profile_img: "",
      table_data: {
        item: "1000",
        views: "2000",
        floor_price: "30000",
        volume: "40000",
      },
    },
    {
      id: "7",
      name: "Sephoraa",
      table_name: "Sephora",
      background_img: "",
      profile_img: "",
      table_data: {
        item: "1000",
        views: "2000",
        floor_price: "30000",
        volume: "40000",
      },
    },
  ],
  card2: [
    {
      id: "4",
      name: "Sephoaaaaaraa",
      table_name: "Sephora",
      background_img: "",
      profile_img: "",
      table_data: {
        item: "1000",
        views: "2000",
        floor_price: "30000",
        volume: "40000",
      },
    },
    {
      id: "5",
      name: "Sephoraa",
      table_name: "Sephora",
      background_img: "",
      profile_img: "",
      table_data: {
        item: "1000",
        views: "2000",
        floor_price: "30000",
        volume: "40000",
      },
    },
    {
      id: "6",
      name: "Sephoraa",
      table_name: "Sephora",
      background_img: "",
      profile_img: "",
      table_data: {
        item: "1000",
        views: "2000",
        floor_price: "30000",
        volume: "40000",
      },
    },
    {
      id: "7",
      name: "Sephoraa",
      table_name: "Sephora",
      background_img: "",
      profile_img: "",
      table_data: {
        item: "1000",
        views: "2000",
        floor_price: "30000",
        volume: "40000",
      },
    },
  ],
};

export function getSection2() {
  return section2;
}
